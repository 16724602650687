export const localScroll = (element, to, duration) => {

    if (duration <= 0) return;

    let b = element.scrollTop;
    let c = to - element.scrollTop;

    animateLoop(element, 0, b, c, duration);

    function animateLoop(element, currentTick, b, c, d) {
        setTimeout(function () {
            if (d === currentTick * 10) return;

            let t = currentTick * 10;

            currentTick++;

            element.scrollTop = Math.floor(easing(t, b, c, d));

            animateLoop(element, currentTick, b, c, d);
        }, 10);
    }

    function easing(t, b, c, d) {
        // https://spicyyoghurt.com/tools/easing-functions
        // t = Current time
        // b = Begin value
        // c = Change in value
        // d = Duration

        if ((t /= d / 2) < 1) return c / 2 * t * t + b;
        return -c / 2 * ((--t) * (t - 2) - 1) + b;
    }

}